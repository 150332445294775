import type { HTMLProps, ReactNode } from "react";

import * as styles from "./CarouselSpecializedRadio.styles";

interface Props extends HTMLProps<HTMLInputElement> {
  children?: ReactNode;
}

/** @deprecated Please use NewCarousel instead */
const CarouselSpecializedRadio = ({ id, hidden, disabled, children, ...rest }: Props) => {
  return (
    <label css={[styles.radioWrapper, hidden && styles.hidden, disabled && styles.disabled]}>
      <input id={id} type="radio" css={styles.radio} disabled={disabled} {...rest} />
      <span />
      <div css={styles.radioContent}>{children}</div>
    </label>
  );
};

export { CarouselSpecializedRadio };
