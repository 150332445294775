import { css, type Theme } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";

export const container = (theme: Theme) => css`
  background: ${theme.system.backgroundMuted};
  padding: 0.875rem 1.125rem;
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 1rem;
  border-radius: ${dimensions.borderRadius};
`;

export const item = css`
  height: 2.75rem;
  display: flex;
  align-items: center;

  @media (max-width: ${dimensions.phoneMax}) {
    height: 4.25rem;
  }
`;
