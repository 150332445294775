import { usePractitionerTypesQuery } from "@shared/data/queries/PractitionerTypesQuery/PractitionerTypes.query";
import { useSearchParams } from "@shared/react-router-dom/react-router-dom";
import type { PractitionerType } from "@shared/types/graphqlGenerated";

type PracType = Partial<PractitionerType>;

export const useDesignations = (designations?: PracType[]) => {
  const [searchParams] = useSearchParams();
  const { data, loading } = usePractitionerTypesQuery({
    skip: !!designations, // use designations from props instead of query when provided
  });
  const practitionerTypes: PracType[] = data?.practitionerTypes || designations;

  const isMDExperiment = searchParams.get("version") === "mdnd";

  const getTypes = () => {
    if (loading || !practitionerTypes) {
      return null;
    }
    if (!isMDExperiment) {
      return practitionerTypes;
    }

    // filter logic
    const filteredPractitionerTypes = practitionerTypes.filter(type => {
      return (
        type.description === "Licensed Medical Doctor" ||
        type.description === "Licensed Naturopathic Doctor" ||
        type.description === "Other Licensed Healthcare Provider"
      );
    });

    return filteredPractitionerTypes;
  };

  return {
    designations: getTypes(),
    loading,
  };
};
