import type { QueryHookOptions } from "@apollo/client";
import { useQuery, gql } from "@apollo/client";

import type { PractitionerTypeFragment } from "@shared/data/fragments/PractitionerTypeFragment/PractitionerTypeFragment.fragment";
import { PRACTITIONER_TYPE_FRAGMENT } from "@shared/data/fragments/PractitionerTypeFragment/PractitionerTypeFragment.fragment";

const PractitionerTypes_Shared_Query = gql`
  query PractitionerTypes_Shared_Query {
    practitionerTypes {
      ...PractitionerTypeFragment
    }
  }
  ${PRACTITIONER_TYPE_FRAGMENT}
`;

interface PractitionerTypesData {
  practitionerTypes: PractitionerTypeFragment[];
}

const usePractitionerTypesQuery = (options?: QueryHookOptions<PractitionerTypesData>) =>
  useQuery<PractitionerTypesData>(PractitionerTypes_Shared_Query, options);

export type { PractitionerTypesData };

/* eslint-disable @fullscript/gql-no-manual-hook-declaration */
export { usePractitionerTypesQuery, PractitionerTypes_Shared_Query };
