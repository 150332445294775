import { css, type Theme } from "@emotion/react";

import { layers, helpers, animations } from "@styles";

const baseDefaults = css`
  border-radius: 50%;
  position: relative;
  z-index: ${layers.indexDefault};
  align-self: center;
`;

const isDashedBaseDefaults = (theme: Theme) => css`
  background-color: ${theme.system.backgroundBase};
  border-color: ${theme.success.backgroundBase};
  border-style: solid;
  ${baseDefaults}
`;

export const stepWrapper = {
  base: css`
    display: flex;
    position: relative;
    flex: 1;
  `,
  labels: {
    status: css`
      &:last-of-type {
        flex: 0;
      }
    `,
    all: css`
      flex-direction: column;
      align-items: center;
    `,
  },
};

export const steps = {
  base: (theme: Theme) => css`
    background-color: ${theme.system.backgroundMutedHover};
    ${baseDefaults}
  `,
  isDashedBase: {
    small: (theme: Theme) => css`
      border-width: 2px;
      ${isDashedBaseDefaults(theme)}
    `,
    large: (theme: Theme) => css`
      border-width: 4px;
      ${isDashedBaseDefaults(theme)}
    `,
  },
  size: {
    small: (theme: Theme) => css`
      width: 0.75rem;
      min-width: 0.75rem;
      height: 0.75rem;

      svg {
        font-size: ${theme.aviaryTypography.caption.fontSize};
      }
    `,
    large: (theme: Theme) => css`
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;

      svg {
        font-size: ${theme.aviaryTypography.h3.fontSize};
      }
    `,
  },
  current: (theme: Theme) => css`
    background-color: ${theme.system.backgroundBase};
  `,
  currentBorder: {
    successful: {
      small: (theme: Theme) => css`
        border: 2px solid ${theme.success.backgroundBase};
        box-shadow: 0 0 0 2px ${helpers.hexToRgba(theme.success.backgroundBase, 0.15)};
      `,
      large: (theme: Theme) => css`
        border: 4px solid ${theme.success.backgroundBase};
        box-shadow: 0 0 0 4px ${helpers.hexToRgba(theme.success.backgroundBase, 0.15)};
      `,
    },
    error: {
      small: (theme: Theme) => css`
        border: 2px solid ${theme.warning.backgroundBase};
        box-shadow: 0 0 0 2px ${helpers.hexToRgba(theme.warning.backgroundBase, 0.15)};
      `,
      large: (theme: Theme) => css`
        border: 4px solid ${theme.warning.backgroundBase};
        box-shadow: 0 0 0 4px ${helpers.hexToRgba(theme.warning.backgroundBase, 0.15)};
      `,
    },
  },
  previous: (theme: Theme) => css`
    background-color: ${theme.success.backgroundBase};
  `,
  completed: (theme: Theme) => css`
    color: ${theme.success.backgroundBase};
    border: none;
    display: flex;
  `,
  dashedError: (theme: Theme) => css`
    color: ${theme.warning.backgroundBase};
  `,
  error: (theme: Theme) => css`
    background-color: ${theme.warning.backgroundBase};
  `,
  link: (theme: Theme) => css`
    ${animations.transition()};
    &:hover {
      background-color: ${theme.success.backgroundHover};
    }

    &:active {
      background-color: ${theme.success.backgroundActive};
    }
  `,

  dashedLink: (theme: Theme) => css`
    ${animations.transition()};
    &:hover {
      color: ${theme.success.backgroundHover};
    }

    &:active {
      color: ${theme.success.backgroundActive};
    }
  `,
};

export const connectors = {
  base: (theme: Theme) => css`
    &::after {
      position: absolute;
      content: "";
      border-top: 2px solid ${theme.system.backgroundMutedHover};
      width: 100%;
    }

    &:last-of-type {
      &::after {
        content: none;
      }
    }
  `,
  labels: {
    status: css`
      &::after {
        left: 1%;
      }
    `,
    all: css`
      &::after {
        left: 50%;
      }
    `,
  },
  size: {
    small: css`
      &::after {
        border-top-width: 0.25rem;
        top: 0.25rem;
      }
    `,
    large: css`
      &::after {
        border-top-width: 0.5rem;
        top: 0.5rem;
      }
    `,
  },
  current: (theme: Theme) => css`
    &::after {
      border-color: ${theme.system.backgroundMutedHover};
    }
  `,
  completed: (theme: Theme) => css`
    &::after {
      border-color: ${theme.success.backgroundBase};
    }
  `,
  completedGray: (theme: Theme) => css`
    &::after {
      border-color: ${theme.text.body};
    }
  `,
  error: (theme: Theme) => css`
    &::after {
      border-color: ${theme.warning.backgroundBase};
    }
  `,
  dashed: {
    base: (theme: Theme) => css`
      &::after {
        border-top: 2px dashed ${theme.success.backgroundBase};
      }
    `,
    small: css`
      &::after {
        top: 0.3rem;
      }
    `,
    large: css`
      &::after {
        top: 0.75rem;
      }
    `,
  },
  firstStep: css`
    &::after {
      border-radius: 2px 0 0 2px;
    }
  `,
  finalStep: css`
    &::after {
      border-radius: 0 2px 2px 0;
    }
  `,
};

export const labels = {
  all: css`
    margin-top: 0.5rem;
    text-align: center;
  `,
  error: (theme: Theme) => css`
    color: ${theme.warning.textHover};
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.25rem;
      font-size: ${theme.aviaryTypography.body.fontSize};
    }
  `,
  status: {
    centered: css`
      text-align: center;
      justify-content: center;
    `,
    size: {
      small: css`
        margin-top: 0.5rem;
      `,
      large: css`
        margin-top: 1rem;
      `,
    },
  },
};

export const stepperWrapper = css`
  display: flex;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
`;
