import { l } from "@shared/locales/i18n";
import { StorePlatform } from "@shared/types/StorePlatform";

const getPlatformName = (storePlatform: StorePlatform) => {
  switch (storePlatform) {
    case StorePlatform.EMERSON:
      return l.auth.platforms.EmersonEcologics;

    default:
      return l.auth.platforms.Fullscript;
  }
};

export { getPlatformName };
