import { useTranslation } from "react-i18next";

import { Link, Spacer, Typography } from "@aviary";
import type { TwoFactorAuthenticationMethods } from "@shared/types/graphqlGenerated";
import { l } from "@unauthenticated/shared/locales/i18n";

interface Props {
  otpMethod: TwoFactorAuthenticationMethods;
  onClick: () => void;
}

const BackupCodeSignInLink = ({ otpMethod, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography type="footnote" isMarginless>
        {t(l.signIn.twoFactorAuthForm.unableToAccessOtpMethod[otpMethod])}
      </Typography>
      <Spacer height="half" />
      <Typography type="footnote">
        <Link onClick={onClick}>{t(l.signIn.twoFactorAuthForm.useBackupCodeToSignIn)}</Link>
      </Typography>
      <Spacer height="oneHalf" />
    </>
  );
};

export { BackupCodeSignInLink };
