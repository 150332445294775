import { css } from "@emotion/react";

export const iframeWrapper = css`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  width: 100%;
  height: 0;
  margin-bottom: 1rem;
`;

export const iframe = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
