import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const PRACTITIONER_SIGN_UP_LOGGER_MUTATION = gql`
  mutation PractitionerSignUpLogger_UnauthenticatedShared_Mutation(
    $input: AuthLoggersPractitionerSignUpInput!
  ) {
    auth {
      practitionerSignUpLogger(input: $input) {
        errors {
          message
        }
      }
    }
  }
`;

interface AuthLoggersPractitionerSignUpError {
  message: string;
}

interface PractitionerSignUpLoggerData {
  auth: {
    practitionerSignUpLogger: {
      errors: AuthLoggersPractitionerSignUpError;
    };
  };
}

interface AuthLoggersPractitionerSignUpAttributes {
  eventType: string;
  eventAggregateId: string;
  signUpPage: string;
  storePlatform: string;
  pageVersion: string;
  field?: string;
  apiClientId?: string;
}

interface Variables {
  input: {
    attributes: AuthLoggersPractitionerSignUpAttributes;
  };
}

const usePractitionerSignUpLoggerMutation = (
  options?: MutationHookOptions<PractitionerSignUpLoggerData, Variables>
) =>
  useMutation<PractitionerSignUpLoggerData, Variables>(
    PRACTITIONER_SIGN_UP_LOGGER_MUTATION,
    options
  );

export type { PractitionerSignUpLoggerData };
export { usePractitionerSignUpLoggerMutation, PRACTITIONER_SIGN_UP_LOGGER_MUTATION };
