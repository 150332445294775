import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

export const badge = (theme: Theme) => css`
  font-weight: ${typography.weightSemiBold};
  position: relative;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-shrink: 0;

  background-color: ${theme.info.backgroundMutedHover};
  color: ${theme.info.textHover};
  line-height: 0;
`;

export const badgeSize = {
  xsmall: css`
    width: 1.125rem;
    height: 1.125rem;
  `,
  small: css`
    width: 1.125rem;
    height: 1.125rem;
  `,
  medium: css`
    width: 1.5rem;
    height: 1.5rem;
  `,
  large: css`
    width: 2rem;
    height: 2rem;
  `,
  xlarge: css`
    width: 2rem;
    height: 2rem;
  `,
};

export const fontSize = {
  xsmall: (theme: Theme) => css`
    font-size: ${theme.aviaryTypography.subcaption.fontSize};
  `,
  small: (theme: Theme) => css`
    font-size: ${theme.aviaryTypography.subcaption.fontSize};
  `,
  medium: (theme: Theme) => css`
    font-size: ${theme.aviaryTypography.footnote.fontSize};
  `,
  large: css`
    font-size: 1.125rem;
  `,
  xlarge: css`
    font-size: 1.125rem;
  `,
};
