import type { MutationHookOptions } from "@apollo/client";
import { gql, useMutation } from "@apollo/client";

import type { AuthUserTwoFactorAuthenticationResendError } from "@shared/types/graphqlGenerated";

const ResendTwoFactorAuthPasscode_UnauthenticatedShared_Mutation = gql`
  mutation ResendTwoFactorAuthPasscode_UnauthenticatedShared_Mutation(
    $input: AuthUserTwoFactorAuthenticationResendInput!
  ) {
    auth {
      userResendTwoFactorAuthentication(input: $input) {
        sent
        errors {
          message
        }
      }
    }
  }
`;

interface ResendTwoFactorAuthPasscodeData {
  auth: {
    userResendTwoFactorAuthentication: {
      sent: boolean;
      errors: AuthUserTwoFactorAuthenticationResendError;
    };
  };
}

interface Variables {
  input: {
    captchaToken: string;
    token: string;
  };
}

const useResendTwoFactorAuthPasscodeMutation = (
  options?: MutationHookOptions<ResendTwoFactorAuthPasscodeData, Variables>
) =>
  useMutation<ResendTwoFactorAuthPasscodeData, Variables>(
    ResendTwoFactorAuthPasscode_UnauthenticatedShared_Mutation,
    options
  );

export type { ResendTwoFactorAuthPasscodeData };
export {
  useResendTwoFactorAuthPasscodeMutation,
  ResendTwoFactorAuthPasscode_UnauthenticatedShared_Mutation,
};
