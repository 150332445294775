import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

import { animations, timing } from "@styles";

export const radioWrapper = css`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  position: relative;

  font-size: 1rem;
  font-weight: ${typography.weightBase};
`;

export const hidden = css`
  display: none;
`;

export const disabled = css`
  user-select: none;
  opacity: 0.8;
`;

export const radio = (theme: Theme) => css`
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  vertical-align: baseline;

  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;

    & + span {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: ${theme.system.textBase};
      height: 1rem;
    }
    & + span:before,
    & + span:after {
      ${animations.transition(undefined, timing.moderatelySlow)}
      content: "";
      position: absolute;
    }
    & + span:before {
      left: 0;
      top: 0;
      width: 0.5rem;
      height: 0.5rem;
      border: 1px solid ${theme.system.borderHover};
      border-radius: 100%;
      background: ${theme.system.borderBase};
    }
    & + span:after {
      width: 0.5rem;
      height: 0.5rem;
      background: ${theme.success.backgroundBase};
      top: 0;
      left: 0;
      border-radius: 100%;
    }
  }

  &:not(:checked) + span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &:checked + span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const radioContent = css`
  width: 100%;
`;
