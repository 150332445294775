import { useCallback, useState } from "react";

const useBooleanAggregator = (initialState = 0): [boolean, () => void, () => void] => {
  const [count, setCount] = useState(initialState);

  const increment = useCallback(() => setCount(prevCount => prevCount + 1), []);
  const decrement = useCallback(
    () => setCount(prevCount => (prevCount === 0 ? prevCount : prevCount - 1)),
    []
  );

  const active = count > 0;

  return [active, increment, decrement];
};

export { useBooleanAggregator };
