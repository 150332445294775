import { css } from "@emotion/react";

export const backupCode = css`
  letter-spacing: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const backupCodeWrapper = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0;
`;
