import { css } from "@emotion/react";

export const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  text-align: center;
`;

export const nextButton = css`
  margin-top: 2rem;
  margin-bottom: 3rem;
`;
