import { useEffect } from "react";

import type {
  SignUpPageType,
  PlatformType,
} from "@unauthenticated/shared/components/SimplifySignUp/types";

import { usePractitionerSignUpLoggerMutation } from "./PractitionerSignUpLogger.mutation";

type Input = {
  eventAggregateId: string;
  signUpPage: SignUpPageType;
  pageVersion: string;
  storePlatform?: PlatformType;
  fields?: string[];
  apiClientId?: string;
};

const cache = {};

const useLogPractitionerSignUp = ({
  eventAggregateId,
  signUpPage,
  pageVersion,
  storePlatform,
  fields,
  apiClientId,
}: Input) => {
  const [log] = usePractitionerSignUpLoggerMutation();

  const logFieldExpected = field =>
    log({
      variables: {
        input: {
          attributes: {
            eventAggregateId,
            eventType: "field_expected",
            pageVersion,
            signUpPage,
            storePlatform,
            field,
            apiClientId,
          },
        },
      },
    });

  const logFieldsExpected = () => {
    if (fields) {
      fields.forEach(logFieldExpected);
    } else {
      ["first_name", "last_name", "email", "password", "practitioner_type"].forEach(
        logFieldExpected
      );
    }
  };

  useEffect(() => {
    if (cache[signUpPage]) return;
    cache[signUpPage] = true;
    log({
      variables: {
        input: {
          attributes: {
            eventAggregateId,
            eventType: "session_begin",
            pageVersion,
            signUpPage,
            storePlatform,
            apiClientId,
          },
        },
      },
    }).then(() => {
      logFieldsExpected();
    });
  }, []);

  const logField = (field: string) => {
    if (cache[field]) return;
    cache[field] = true;
    log({
      variables: {
        input: {
          attributes: {
            field,
            eventAggregateId,
            eventType: "field_updated",
            pageVersion,
            signUpPage,
            storePlatform,
            apiClientId,
          },
        },
      },
    });
  };

  return { logField };
};

export { useLogPractitionerSignUp };
