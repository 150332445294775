import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const PRACTITIONER_SIGN_UP_MUTATION = gql`
  mutation PractitionerSignUp_UnauthenticatedShared_Mutation($input: AuthPractitionerSignUpInput!) {
    auth {
      practitionerSignUp(input: $input) {
        redirectPath
        practitioner {
          id
          storeId
        }
        errors {
          message
          fields {
            email
            firstName
            lastName
            storeName
            password
            practitionerTypeId
          }
        }
      }
    }
  }
`;

interface AuthPractitionerSignUpFieldError {
  email: string[];
  firstName: string[];
  lastName: string[];
  storeName: string[];
  password: string[];
  practitionerTypeId: string[];
}

interface AuthPractitionerSignUpError {
  fields: AuthPractitionerSignUpFieldError;
  message: string;
}

interface PractitionerSignUpMutationData {
  auth: {
    practitionerSignUp: {
      errors: AuthPractitionerSignUpError;
      redirectPath: string;
      practitioner: {
        id: string;
        storeId: string;
      };
    };
  };
}

interface AuthPractitionerSignUpAttributes {
  firstName: string;
  lastName: string;
  storeName?: string;
  practitionerTypeId: string;
  email: string;
  password: string;
  pageVersion: string;
  signUpPage: string;
  tosAcceptedAt?: string;
  storePlatform?: string;
  optional?: any;
}

interface Variables {
  input: {
    captchaToken: string;
    attributes: AuthPractitionerSignUpAttributes;
  };
}

const usePractitionerSignUpMutation = (
  options?: MutationHookOptions<PractitionerSignUpMutationData, Variables>
) => useMutation<PractitionerSignUpMutationData, Variables>(PRACTITIONER_SIGN_UP_MUTATION, options);

export type { PractitionerSignUpMutationData };
export { usePractitionerSignUpMutation, PRACTITIONER_SIGN_UP_MUTATION };
