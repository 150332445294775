export const urlRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const IsUrl = (value: string) => {
  return new RegExp(urlRegex).test(value);
};

export const IsNameValid = (value: string) => {
  if (value) {
    return !IsUrl(value);
  }

  return false;
};
