import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import type { FloatingLabelInputProps } from "@aviary";
import { FloatingLabelInput, Tooltip } from "@aviary";
import { IsNameValid } from "@unauthenticated/shared/components/NameValidation/NameValidation";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./FirstNameInput.styles";

interface Props extends Omit<FloatingLabelInputProps, "id" | "label" | "type"> {
  errors?: ReactNode[];
  isDirty?: boolean;
  value: string;
  isLoading: boolean;
}

const FirstNameInput = ({
  wrapperStyles,
  isDirty,
  errors,
  disabled,
  isLoading,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const isValid = () => {
    if (!isDirty) return null;
    const newErrors = errors || [];
    if (rest?.value) {
      if (!IsNameValid(rest.value)) {
        return [...newErrors, t(l.common.FirstNameInvalid)];
      }

      return newErrors;
    }

    return [...newErrors, t(l.common.FirstNameRequired)];
  };

  const getErrors = () => {
    return isValid();
  };

  const renderInput = () => (
    <FloatingLabelInput
      id="firstName"
      data-e2e="first_name"
      type="text"
      name="firstName"
      label={t(l.common.FirstName)}
      wrapperStyles={wrapperStyles}
      errors={getErrors()}
      disabled={disabled || isLoading}
      {...rest}
    />
  );

  if (disabled) {
    return (
      <Tooltip
        tooltipContent={t(l.common.EditOnceSignedIn)}
        css={[styles.Tooltip, wrapperStyles]}
        data-testid="disabled-tooltip"
      >
        {renderInput()}
      </Tooltip>
    );
  }

  return renderInput();
};
export { FirstNameInput };
