import type { ReactNode } from "react";
import { useCallback, useRef, useEffect, useState, Children, cloneElement } from "react";
import { config, useTransition } from "react-spring";

import { useCarouselContext } from "../CarouselContext";

import * as styles from "./CarouselItems.styles";

interface Props {
  children?: ReactNode | ReactNode[];
  disableAnimation?: boolean;
}

/** @deprecated Please use NewCarousel instead */
const CarouselItems = ({ disableAnimation, children, ...rest }: Props) => {
  const firstRender = useRef(true);
  const { activeIndex, direction, itemsToShow, loop, itemsToScroll, carouselItemsCount } =
    useCarouselContext();

  const getItemsToShow = useCallback(
    (transitionActiveIndex: number) => {
      const items = Children.toArray(children);
      const lastIndex = Math.min(transitionActiveIndex + itemsToShow, items.length);
      let result = items.slice(transitionActiveIndex, lastIndex) as any[];

      if (result.length < itemsToShow) {
        if (loop) result = [...result, ...items.slice(0, itemsToShow - result.length)];
        result = [
          ...items.slice(
            transitionActiveIndex - itemsToScroll + result.length,
            transitionActiveIndex
          ),
          ...result,
        ];
      }

      return result;
    },
    [itemsToShow, children, itemsToScroll, loop]
  );

  const [carouselItems, setCarouselItems] = useState(getItemsToShow(activeIndex));

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      return;
    }

    setCarouselItems(getItemsToShow(activeIndex));
  }, [activeIndex, children, getItemsToShow]);

  const from = direction === "right" ? "100%" : "-100%";
  const leave = direction === "right" ? "-100%" : "100%";

  const transition = useTransition(carouselItems, {
    initial: {
      transform: disableAnimation ? "translate3d(0%, 0, 0)" : `translate3d(${from}, 0, 0)`,
    },
    from: {
      transform: disableAnimation ? "translate3d(0%, 0, 0)" : `translate3d(${from}, 0, 0)`,
      position: "initial",
    },
    enter: { transform: "translate3d(0%, 0, 0)" },
    leave: {
      transform: disableAnimation ? "translate3d(0%, 0, 0)" : `translate3d(${leave}, 0, 0)`,
      position: "absolute",
      visibility: "hidden",
    },
    config: config.gentle,
    keys: null,
  });

  return (
    <div css={styles.carouselItems({ itemsToShow, carouselItemsCount })} {...rest}>
      {transition((style, item) =>
        cloneElement(item, {
          ...item.props,
          animatedstyle: style,
          animatedcss: styles.animatedDiv({ itemsToShow, carouselItemsCount }),
        })
      )}
    </div>
  );
};

export { CarouselItems };
