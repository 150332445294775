import type { SerializedStyles } from "@emotion/react";

import * as styles from "./EmbeddedVideo.styles";

interface Props {
  src: string;
  title: string;
  customStyles?: SerializedStyles;
}

const EmbeddedVideo = ({ customStyles, src, title }: Props) => {
  if (!src) {
    return null;
  }

  return (
    <div css={styles.iframeWrapper}>
      <iframe
        data-testid="embedded-video"
        css={[styles.iframe, customStyles]}
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}
      ></iframe>
    </div>
  );
};

export { EmbeddedVideo };
