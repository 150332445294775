import type { HTMLProps, ReactNode } from "react";
import { a } from "react-spring";

import * as styles from "./CarouselItem.styles";

interface Props extends HTMLProps<HTMLDivElement> {
  /**
   * @deprecated Do not use! This is modified internally within Carousel at runtime.
   */
  animatedstyle?: any;
  /**
   * @deprecated Do not use! This is modified internally within Carousel at runtime.
   */
  animatedcss?: any;
  /**
   * Content to be displayed as an item within the Carousel
   *
   * @default undefined
   */
  children?: ReactNode;
}

/** @deprecated Please use NewCarousel instead */
const CarouselItem = ({ animatedcss, animatedstyle, children, ...rest }: Props) => {
  return (
    <a.div style={animatedstyle} css={animatedcss}>
      <div css={styles.carouselItem} {...rest}>
        {children}
      </div>
    </a.div>
  );
};

export { CarouselItem };
