import { useCarouselContext } from "@aviary/components/Carousel/CarouselContext";

import { CarouselSpecializedRadio } from "./CarouselSpecializedRadio";

import * as styles from "./CarouselRadio.styles";

/** @deprecated Please use NewCarousel instead */
const CarouselRadio = () => {
  const { activeIndex, setActiveIndex, carouselItemsCount, setDirection, itemsToShow } =
    useCarouselContext();

  const handleChange = i => {
    const newActiveIndex = i * itemsToShow;
    setActiveIndex(newActiveIndex);
    setDirection(newActiveIndex > activeIndex ? "right" : "left");
  };

  const radiosCount = Math.ceil(carouselItemsCount / itemsToShow);

  if (radiosCount <= 1) return null;

  const activeRadio = Math.floor(activeIndex / itemsToShow);

  return (
    <div css={styles.carouselRadio}>
      {Array.from({ length: radiosCount }).map((_, i) => {
        return (
          <CarouselSpecializedRadio
            key={i}
            checked={activeRadio === i}
            onChange={() => handleChange(i)}
          />
        );
      })}
    </div>
  );
};

export { CarouselRadio };
