import { css, type Theme } from "@emotion/react";
import type { ColorProfileTheme } from "aviary-tokens";

import { dimensions } from "@styles";

export const cell = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  border-radius: ${dimensions.cardRadius};
  padding: 0.5rem 1rem;

  color: ${theme.text.body};

  &:not(:last-of-type) {
    margin-bottom: ${dimensions.containerMarginMobile};
  }
`;

export const marginless = css`
  &:not(:last-of-type) {
    margin-bottom: 0;
  }
`;

export const flexRow = css`
  flex-direction: row;
`;

export const justify = {
  start: css`
    justify-content: flex-start;
  `,
  end: css`
    justify-content: flex-end;
  `,
  center: css`
    justify-content: center;
  `,
  spaceBetween: css`
    justify-content: space-between;
  `,
  spaceAround: css`
    justify-content: space-around;
  `,
  spaceEvenly: css`
    justify-content: space-evenly;
  `,
};

export const background = (profile: ColorProfileTheme) => css`
  background-color: ${profile.backgroundMuted};
`;

export const verticalPadding = {
  none: css`
    padding-top: 0;
    padding-bottom: 0;
  `,
  extraTight: css`
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  `,
  tight: css`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  `,
  baseTight: css`
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  `,
  normal: css`
    padding-top: 1rem;
    padding-bottom: 1rem;
  `,
  loose: css`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  `,
  extraLoose: css`
    padding-top: 2rem;
    padding-bottom: 2rem;
  `,
};

export const fitContent = css`
  width: fit-content;
`;

export const lightBackground = (theme: Theme) => css`
  background-color: ${theme.surface.level1};
`;
