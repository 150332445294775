import { Trans } from "react-i18next";

import { Typography } from "@aviary";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./SignUpTitle.styles";

interface Props {
  user: string;
}

const SignUpTitle = ({ user }: Props) => {
  return (
    <Typography type="h3" isMarginless>
      <Trans i18nKey={l.practitionerSignUp.userSignup}>
        <span css={styles.user}>{{ user }}</span> sign-up
      </Trans>
    </Typography>
  );
};

export { SignUpTitle };
