import type { ChangeEvent, SyntheticEvent } from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { FloatingLabelInput, Typography, Button, Separator, Spacer, useToast } from "@aviary";
import { BackButton } from "@aviary/layouts";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { TwoFactorAuthenticationMethods } from "@shared/types/graphqlGenerated";
import { BackupCodeForm } from "@unauthenticated/shared/components/TwoFactorAuthenticationForm/BackupCodeForm/BackupCodeForm";
import { BackupCodeSignInLink } from "@unauthenticated/shared/components/TwoFactorAuthenticationForm/BackupCodeSignInLink/BackupCodeSignInLink";
import { NewBackupCodeView } from "@unauthenticated/shared/components/TwoFactorAuthenticationForm/NewBackupCodeView/NewBackupCodeView";
import { ResendOtp } from "@unauthenticated/shared/components/TwoFactorAuthenticationForm/ResendOtp/ResendOtp";
import { l } from "@unauthenticated/shared/locales/i18n";

import { useVerifyTwoFactorAuthenticationMutation } from "./VerifyTwoFactorAuthentication.mutation";

import * as styles from "./TwoFactorAuthenticationForm.styles";

interface RedirectData {
  redirectPath: string;
  roleType: string;
}

interface Props {
  userEmail: string;
  phoneLast4?: string;
  otpMethod: TwoFactorAuthenticationMethods;
  twoFactorAuthToken: string;
  onBackToSignIn?: () => void;
  onUserLockedOut: () => void;
  onCompleted?: () => void;
}

const TwoFactorAuthenticationForm = ({
  userEmail,
  phoneLast4,
  otpMethod,
  twoFactorAuthToken,
  onBackToSignIn,
  onUserLockedOut,
  onCompleted,
}: Props) => {
  const { t } = useTranslation();
  const { phoneLarge } = useBreakpoints();
  const { makeToast } = useToast();

  const [oneTimePasscode, setOneTimePasscode] = useState("");
  const [backupCode, setBackupCode] = useState("");
  const [newBackupCode, setNewBackupCode] = useState("");
  const [useBackupCode, setUseBackupCode] = useState(false);
  const [redirectAfterSignIn, setRedirectAfterSignIn] = useState<RedirectData>(null);
  const [showNewBackupCodeView, setShowNewBackupCodeView] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const onVerifyTwoFactorComplete = data => {
    const hasErrors = data?.auth?.userVerifyTwoFactorAuthentication?.errors?.message;

    if (hasErrors) {
      return makeToast("error", t(l.signIn.twoFactorAuthForm.errorMessage));
    }

    const {
      locked,
      redirectPath,
      roleType,
      newBackupCode: updatedBackupCode,
    } = data?.auth?.userVerifyTwoFactorAuthentication || {};

    if (locked) {
      return onUserLockedOut();
    }

    if (redirectPath) {
      if (onCompleted) return onCompleted();

      if (updatedBackupCode) {
        setNewBackupCode(updatedBackupCode);
        setRedirectAfterSignIn({ redirectPath, roleType });
        setShowNewBackupCodeView(true);
      } else {
        window.location.assign(redirectPath);
      }
    } else {
      displayFormErrors();
    }
  };

  const [verifyTwoFactorAuth, { loading }] = useVerifyTwoFactorAuthenticationMutation({
    variables: {
      input: {
        attributes: {
          otp: useBackupCode ? backupCode : oneTimePasscode,
          token: twoFactorAuthToken,
        },
      },
    },
    onCompleted: onVerifyTwoFactorComplete,
    onError: ({ message }) => makeToast("error", message),
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormErrors([]);
    setOneTimePasscode(e.target.value);
  };

  const displayFormErrors = () => {
    const errors = useBackupCode
      ? [t(l.signIn.backupCodeForm.backupCodeIncorrect)]
      : [t(l.signIn.twoFactorAuthForm.oneTimeCodeMismatch)];

    setFormErrors(errors);
  };

  const handleSetBackupCode = code => {
    setBackupCode(code);
  };

  const handleSubmit = (event?: SyntheticEvent) => {
    event?.preventDefault();

    verifyTwoFactorAuth();
  };

  const renderRetrievalInstructions = () => {
    if (otpMethod === TwoFactorAuthenticationMethods.Email) {
      return (
        <Typography>
          <Trans
            i18nKey={l.signIn.twoFactorAuthForm.otpRetrieval.email}
            values={{ email: userEmail }}
          />
        </Typography>
      );
    }

    if (otpMethod === TwoFactorAuthenticationMethods.App) {
      return <Typography>{t(l.signIn.twoFactorAuthForm.otpRetrieval.app)}</Typography>;
    }

    return (
      <Typography>
        <Trans
          i18nKey={l.signIn.twoFactorAuthForm.otpRetrieval.sms}
          values={{ mobileNumber: phoneLast4 }}
        />
      </Typography>
    );
  };

  const renderResendOtp = () => {
    if (
      otpMethod === TwoFactorAuthenticationMethods.Email ||
      otpMethod === TwoFactorAuthenticationMethods.Sms
    ) {
      return (
        <>
          <ResendOtp
            token={twoFactorAuthToken}
            otpMethod={otpMethod}
            email={userEmail}
            phoneLast4={phoneLast4}
          />
          <Separator css={styles.separator} />
        </>
      );
    }

    return <Spacer height="one" />;
  };

  if (showNewBackupCodeView) {
    return (
      <NewBackupCodeView
        newBackupCode={newBackupCode}
        redirectPath={redirectAfterSignIn?.redirectPath}
        roleType={redirectAfterSignIn?.roleType}
      />
    );
  }

  if (useBackupCode) {
    return (
      <BackupCodeForm
        onBackToSignIn={onBackToSignIn}
        backupCode={backupCode}
        onChange={handleSetBackupCode}
        onSubmit={handleSubmit}
        onResetErrors={() => setFormErrors([])}
        errors={formErrors}
      />
    );
  }

  return (
    <form css={styles.wrapper} onSubmit={handleSubmit}>
      <Typography type="h1" sizeOverride="h3">
        {t(l.signIn.twoFactorAuthForm.enterYourCode)}
      </Typography>
      {renderRetrievalInstructions()}
      <div css={styles.otpInputWrapper}>
        <FloatingLabelInput
          id="oneTimePasscode"
          value={oneTimePasscode}
          handleChange={onChange}
          label={t(l.signIn.twoFactorAuthForm.oneTimeCode)}
          type="text"
          errors={formErrors}
          required
        />
      </div>
      {renderResendOtp()}
      <BackupCodeSignInLink otpMethod={otpMethod} onClick={() => setUseBackupCode(true)} />
      <Button
        css={styles.nextButton}
        onClick={handleSubmit}
        isFullWidth={phoneLarge.lessThan}
        disabled={!oneTimePasscode}
        isLoading={loading}
      >
        {t(l.common.Next)}
      </Button>
      <BackButton
        text={t(l.signIn.twoFactorAuthForm.backToSignIn)}
        onClick={onBackToSignIn}
        isUnderLined={false}
      />
    </form>
  );
};

export { TwoFactorAuthenticationForm };
