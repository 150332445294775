import { gql } from "@apollo/client";

/**
 * @deprecated Shared fragment types are deprecated. Use or pick from generated types instead.
 */
interface PractitionerTypeFragment {
  id: string;
  description: string;
  code: string;
  core: boolean;
}

/**
 * @deprecated Shared fragments are deprecated. Indicate field name directly within query/mutation or
 * create a co-located (not shared) fragment, if you need one.
 */
const PRACTITIONER_TYPE_FRAGMENT = gql`
  fragment PractitionerTypeFragment on PractitionerType {
    id
    description
    code
    core
  }
`;

export type { PractitionerTypeFragment };
export { PRACTITIONER_TYPE_FRAGMENT };
