import { css } from "@emotion/react";

export const backButton = css`
  margin: 1rem;
`;

export const backIcon = css`
  margin-right: 0.25rem;
`;

export const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: 26rem;
  margin: 0 auto;
  text-align: center;
`;

export const separator = css`
  margin: 2rem auto;
`;

export const otpInputWrapper = css`
  margin-top: 1rem;
  width: 100%;
`;

export const nextButton = css`
  margin-top: 1rem;
  margin-bottom: 3rem;
`;
