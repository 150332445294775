import { createContext } from "react";

interface LoadingData {
  queryLoading: boolean;
  incrementQueryLoading: () => void;
  decrementQueryLoading: () => void;
  mutationLoading: boolean;
  incrementMutationLoading: () => void;
  decrementMutationLoading: () => void;
}

const LoadingContext = createContext<LoadingData>(null);

export { LoadingContext, type LoadingData };
