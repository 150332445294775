import { css } from "@emotion/react";

export const carousel = css`
  width: 100%;
`;

export const carouselContainer = css`
  width: 100%;
  position: relative;
`;
