interface ExpectedFields {
  fieldName: string[];
}
interface MutationErrors<T> {
  message: string;
  fields: T | ExpectedFields;
}

const hasErrors = <T>(errors: MutationErrors<T>) => {
  const hasFieldError =
    !!errors?.fields &&
    Object.values(errors?.fields || {}).some(
      // array check is to ensure we are getting a field error and not the __typename field which is a string
      fieldError => Array.isArray(fieldError) && fieldError?.length > 0
    );
  const hasTopLevelError = !!errors?.message;

  return hasFieldError || hasTopLevelError;
};

export { hasErrors };
