import { css, type Theme } from "@emotion/react";

export const backButtonContainer = css`
  display: inline-block;
`;

export const backButton = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const margin = css`
  margin-bottom: 1.5rem;
`;

export const chevron = (theme: Theme) => css`
  font-size: ${theme.aviaryTypography.caption.fontSize};
`;
