import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";

import { helpers } from "@styles";

export const button = (theme: Theme) => css`
  width: 100%;
  &:hover,
  &:active {
    box-shadow: 0 1px 2px 0 ${helpers.hexToRgba(theme.text.body, 0.25)};
  }
`;

export const box = (theme: Theme) => css`
  padding: 0.75rem 0.5rem;
  margin-bottom: 0.5rem;
  background-color: transparent;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.system.borderBase};
`;
