import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";

export const wrapper = css`
  margin-top: 1.5rem;
  text-align: center;
`;

export const expirationMessage = (theme: Theme) => css`
  color: ${theme.danger.textBase};
  font-style: italic;
`;
