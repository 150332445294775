import type { HTMLProps, ReactNode } from "react";

import { Box, Link } from "@aviary";
import type { To } from "@shared/react-router-dom/react-router-dom";

import * as styles from "./BoxLink.styles";

interface Props extends HTMLProps<HTMLDivElement> {
  to?: To;
  href?: string;
  children: ReactNode;
}

const BoxLink = ({ to, href, children }: Props) => {
  return (
    <Link to={to} href={href} isUnderLined={false} css={styles.button}>
      <Box css={styles.box}>{children}</Box>
    </Link>
  );
};

export { BoxLink };
